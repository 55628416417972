<template>
  <div>
    <v-row>
      <v-col sm="12" md="12" xl="3">
        <RecipeClosingsFilter
          :exportLoading="exportLoading"
          :loading="loading"
          @filter="getRecipeClosings($event)"
          @clean="getRecipeClosings()"
          @export="
            exportRecipeClosings({
              data: computedClosings,
              date: recipeClosingsDate,
            })
          "
          @select="(checkedFilter = $event), t()"
        />
      </v-col>
      <v-col sm="12" md="12" xl="9">
        <v-sheet class="rounded20 pa-10">
          <ClosingsTable
            :items="computedClosings"
            :loading="loading"
            :propeties="tablePropeties"
            :cardTitle="'Cierres al ' + recipeClosingsDate"
          />
        </v-sheet>
      </v-col>
    </v-row>
    <ClosingsInfo :modal="modalInfo" @close="modalInfo = false" />
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import ClosingsTable from "../../components/recipes/closings/RecipeClosingsTableComponent.vue";
import RecipeClosingsFilter from "../../components/recipes/closings/RecipeClosingsFilterComponent.vue";
import ClosingsInfo from "../../components/recipes/closings/InfoDialogComponent.vue";

export default {
  components: {
    ClosingsTable,
    RecipeClosingsFilter,
    ClosingsInfo,
  },

  data: () => ({
    modalInfo: true,
    tablePropeties: [
      {
        key: "id",
        title: "Receta",
      },
      {
        key: "codAffiliate",
        title: "Cod. afiliado",
      },
      {
        key: "nameAffiliate",
        title: "Afiliado",
      },
      {
        key: "item",
        title: "Artículo",
      },
      {
        key: "prescriptionDate",
        title: "Prescripción",
      },
      {
        key: "deliveryDate",
        title: "Envío",
      },
      {
        key: "receptionDate",
        title: "Recepción",
      },
      {
        key: "dispensationDate",
        title: "Dispensa",
      },
      {
        key: "status",
        title: "Estado",
      },
    ],
    checkedFilter: {
      all: true,
      received: true,
      notReceived: true,
    },
  }),

  mounted() {
    this.getRecipeClosings();
  },

  computed: {
    ...mapGetters("closings", [
      "recipeClosings",
      "recipeClosingsDate",
      "loading",
      "exportLoading",
    ]),

    computedClosings: function () {
      return this.recipeClosings.map((x) => {
        return {
          ...x,
          export: this.checked(x),
        };
      });
    },
  },

  methods: {
    ...mapActions("closings", ["getRecipeClosings", "exportRecipeClosings"]),
    checked(item) {
      const { all, received, notReceived } = this.checkedFilter;

      if (all) {
        return true;
      } else if (received) {
        return item.receptionDate !== "-";
      } else if (notReceived) {
        return item.receptionDate === "-";
      }

      return false;
    },
  },
};
</script>

