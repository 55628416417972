<template>
  <div>
    <div v-if="!loading">
      <v-sheet class="pa-5 rounded20">
        <v-card-title class="overline">
          Filtrar cierre de recetas
        </v-card-title>
        <v-card-text>
          <v-menu
            ref="menu2"
            v-model="datePickerMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            min-width="290px"
            offset-x
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date"
                label="Fecha de cierre"
                prepend-icon="mdi-calendar"
                v-bind="attrs"
                v-on="on"
                clearable
                @keyup.enter="onFilter(), (datePickerMenu = false)"
              />
            </template>
            <v-date-picker
              locale="es-es"
              :allowed-dates="allowedDates"
              v-model="date"
            />
          </v-menu>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="$emit('clean')">
            Limpiar filtros
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="$emit('filter', { date }), (datePickerMenu = false)"
          >
            Filtrar
          </v-btn>
        </v-card-actions>
      </v-sheet>
      <br />
      <v-sheet class="pa-5 rounded20">
        <v-card-title class="overline"> Exportar cierres </v-card-title>
        <v-card-text>
          <v-switch color="teal" label="Todas" v-model="all" hide-details />
          <v-checkbox
            color="teal lighten-3"
            v-model="received"
            label="Recepcionadas"
            hide-details
          />
          <v-checkbox
            color="teal lighten-3"
            v-model="notReceived"
            label="Enviadas "
            hide-details
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="teal"
            text
            @click="
              $emit('select', {
                all,
                received,
                notReceived,
              })
            "
          >
            Seleccionar
          </v-btn>
          <v-btn
            color="teal"
            text
            :loading="exportLoading"
            @click="$emit('export')"
          >
            Exportar
          </v-btn>
        </v-card-actions>
      </v-sheet>
    </div>
    <v-skeleton-loader v-else type="card" />
  </div>
</template>
<script>
import { dateIsBeforeNow } from "../../../services/utils";

export default {
  name: "RecipeFilter",

  props: {
    exportLoading: {
      type: Boolean,
      required: false,
      default: false,
    },

    loading: {
      type: Boolean,
      required: true,
    },
  },

  data: () => ({
    date: null,
    menu: false,
    datePickerMenu: false,
    received: true,
    notReceived: true,
    all: true,
  }),
  computed: {
    allOn: function () {
      if (this.received === true && this.notReceived === true) return true;
      return false;
    },
  },
  watch: {
    all: function (val) {
      if (val === true) {
        this.received = val;
        this.notReceived = val;
      }
    },
    received: function (val) {
      if (val === false) this.all = false;
      this.checkAll();
    },
    notReceived: function (val) {
      if (val === false) this.all = false;
      this.checkAll();
    },
  },
  methods: {
    allowedDates: (val) => dateIsBeforeNow(val),
    checkAll() {
      if (this.received && this.notReceived) this.all = true;
    },
  },
};
</script>
