<template>
  <v-dialog transition="dialog-top-transition" max-width="600" v-model="modal">
    <template v-slot:default="dialog">
      <v-card style="border-radius:20px" class="text-center">
        <v-icon color="red" size="70 " class="py-6">mdi-information</v-icon>
        <v-card-text class="text-h6">
          Informamos que la funcion <strong>CIERRES</strong> está presente sólo
          a modo <strong><i>INFORMATIVO</i></strong> y de
          <strong><i>ORDENAMIENTO</i></strong> para su Farmacia.
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn
            class="py-6 px-6"
            dark
            rounded
            outlined
            block
            color="red"
            @click="$emit('close')"
            >Entendido</v-btn
          >
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
export default {
  name: "ClosingsInfo",
  props: {
    modal: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

